import React, { useState, useEffect } from "react";
import useRoomState from "../hooks/room-state";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Modal,
  Tab,
  Row,
  Col,
  ListGroup,
} from "react-bootstrap";
import "./../styles/mainstreamview.scss";

function AdminPanel(props) {
  const { roomId, userId, roomRef } = props;
  const roomState = useRoomState();
  const { isYouOwner, setIsYouOwner } = roomState;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    roomState.setOwnerId(roomId);
  }, [props]);
  useEffect(() => {
    if (roomRef) {
      // This gets the first item from the queue list. Always in order per firebase
      roomRef.child("ownerId").on("value", (snapshot) => {
        const val = snapshot.val();
        if (val) {
          console.log(val);
          setIsYouOwner(userId, val);
        }
      });
    }
  }, [roomRef]);
  const makeUserControlPanel = (user) => {
    return <div className="control-user-panel">user</div>;
  };
  return isYouOwner ? (
    <>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`adminstatus`}>
            <strong>
              <p>You are an admin</p>
            </strong>
          </Tooltip>
        }
      >
        <Button onClick={handleShow} variant="primary admin-button">
          <i className="fa fa-gavel"></i>
        </Button>
      </OverlayTrigger>
      <Modal show={show} onHide={handleClose} className="app-modal">
        <Modal.Header closeButton>
          <Modal.Title>Admin Panel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="profile">
            <Tab eventKey="home" title="Users">
              Coming soon... ban users and assign lieutenants
            </Tab>
            <Tab eventKey="profile" title="History">
              Coming soon... History of streams
            </Tab>
          </Tabs>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  ) : null;
}

export default AdminPanel;
