import React, { useEffect, useState } from "react";
import { Dropdown, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import TwitchService from "./../utils/twitch";
import "../styles/streambrowser.scss";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
const optionsBase = [
  {
    label: "Our Favorites for Dredging",
    options: [
      { label: "Just Chatting", value: "509658" },
      { label: "Music", value: "26936" },
      { label: "Beauty & Body Art", value: "509669" },
      { label: "Art", value: "509660" },
      { label: "Food & Drink", value: "509667" },
    ],
  },
  {
    label: "Most Popular Games",
    options: [],
  },
];
export const CategoryPicker = (props) => {
  const [categories, setCategories] = useState([]);
  const { currentCategory, setCurrentCategory } = props;
  useEffect(() => {
    refreshDropdown();
  }, []);
  const transformPayloadForDropdown = (arrayOfItems) => {
    return arrayOfItems
      .map((i) => ({
        value: i.id,
        label: i.name,
      }))
      .filter((i) => i.name !== "Just Chatting");
  };
  const refreshDropdown = async () => {
    try {
      const channels = await TwitchService.getTopCategories();
      if (channels) {
        let options = [...optionsBase];
        options[1]["options"] = [...transformPayloadForDropdown(channels.data)];
        setCategories(options);
      }
    } catch {
      console.log("failure");
    }
  };

  // Style react-select
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 46,
      minHeight: 35,
    }),
    input: (base) => ({
      ...base,
      color: `rgba(($color: white), ($alpha: 0.7))`,
    }),
    singleValue: (base) => ({
      ...base,
      color: `rgba(($color: white), ($alpha: 0.7))`,
    }),
  };
  const handleChange = (val) => {
    setCurrentCategory(val);
  };
  return (
    <Select
      onChange={handleChange}
      value={currentCategory}
      placeholder="Filter by category..."
      styles={customStyles}
      defaultValue={optionsBase[0]["options"][0]}
      closeMenuOnSelect={true}
      className="category-select"
      components={animatedComponents}
      options={categories}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#9146ff",
          primary50: "black",
          primary75: "black",
          primary: "#9146ff",
          neutral0: "#121212",
          neutral50: "white",
          neatral15: "white",
          neutral20: "#121212",
          neutral5: "#121212",
          neutral10: "#121212",
        },
      })}
    />
  );
};
