import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import roomHelper from "../firestore/rooms";
import useRoomState from "../hooks/room-state";
import useQueueState from "../hooks/queue-state";
import "../styles/queue.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Fade from "react-bootstrap/Fade";

function Queue(props) {
  const { roomRef } = props;
  const [currentQueue, setCurrentQueue] = useState(null);
  const [queueKeys, setQueueKeys] = useState(null);
  // Grants us access to the state manager for the visible stream
  const queueState = useQueueState();
  // Queue State
  const { enforcedStream } = queueState;
  const { isYouOwner } = useRoomState();
  // Update the array that holds our keys in order as they are in firebase
  useEffect(() => {
    if (currentQueue) {
      const theseQueueKeys = Object.keys(currentQueue);
      setQueueKeys(theseQueueKeys);
    }
  }, [currentQueue]);

  // Update queue state
  useEffect(() => {
    if (roomRef) {
      // 'Queue' child of room is the whole queue :)
      roomRef.child("queue").on("value", (snapshot) => {
        setCurrentQueue(snapshot.val());
        if (snapshot.val() === null) {
          setCurrentQueue(null);
        }
      });
    }
    // When component dismounts we can turn off
    return function cleanup() {
      if (roomRef) {
        roomRef.child("queue").off("value");
      }
    };
  }, [roomRef]);

  // Remove an item by key
  // For specific removal of stream in queue
  // 'key' is the uid on the child item
  const removeItem = (key) => {
    if (roomRef) {
      roomRef.child("queue").child(key).set(null);
    }
  };

  // Get first index of the array of keys which will be the first
  // Null the value to 'skip' to next video
  const goToNextInQueue = () => {
    // if we have a forced stream just clear and queue will take over automatically
    if (enforcedStream !== null || undefined) {
      roomRef.child("enforcedStream").set(null);
    } else if (roomRef && currentQueue && queueKeys) {
      const firstItemId = queueKeys[0];
      if (firstItemId) {
        roomRef.child("queue").child(firstItemId).set(null);
      }
    }
  };

  return (
    <div className="listContainer">
      {isYouOwner ? (
        <Button className="nextButton" onClick={() => goToNextInQueue()}>
          <span style={{ marginRight: 10 }}>Next</span>
          <i className="fas fa-forward"></i>
        </Button>
      ) : null}
      {currentQueue &&
        Object.keys(currentQueue).map((key, index) => {
          const { imageLink } = currentQueue[key];
          return (
            <div
              key={key}
              className={`imageContainer`}
              style={{ marginBottom: `${index === 0 ? "25px" : "0px"}` }}
            >
              <img
                className={`queueImage ${
                  index === 0 ? "currentlyPlaying" : null
                }`}
                src={imageLink}
                alt="Some image"
              />
              {isYouOwner ? (
                <div className="middle" onClick={() => removeItem(key)}>
                  <div className="cancel-text">X</div>
                </div>
              ) : null}
            </div>
          );
        })}
    </div>
  );
}

Queue.propTypes = {};

export default Queue;
