const firebase = require("firebase");
firebase.initializeApp({
  apiKey: "AIzaSyCsS5J1MYeWYR5LGYI0kkun9HdD2msMIx8",
  authDomain: "party-dredge.firebaseapp.com",
  databaseURL: "https://party-dredge.firebaseio.com",
  projectId: "party-dredge",
  storageBucket: "party-dredge.appspot.com",
  messagingSenderId: "1098199003661",
  appId: "1:1098199003661:web:f0533173542ab295455b44",
});

export default firebase;
