import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../styles/streambrowser.scss";
import roomHelper from "../../firestore/rooms";
import useRoomState from "../../hooks/room-state";
import { Image } from "react-bootstrap";

function StreamGrid(props) {
  const url =
    process.env.NODE_ENV === "development"
      ? "http://localhost:5001/party-dredge/us-central1"
      : "https://us-central1-party-dredge.cloudfunctions.net";
  const [roomRef, setRoomRef] = useState(null);
  const { usersPage, setFieldForRoom } = props;
  // Access to ownership variable and room information
  const roomState = useRoomState();
  // Room State
  const { isYouOwner, ownerId } = roomState;

  useEffect(() => {
    if (ownerId) {
      setRoomRef(roomHelper.getRoomRefFromId(ownerId));
    }
  }, [ownerId]);

  const addToQueue = (user_name) => {
    getLogo(user_name, (imageLink) => {
      // Initialize the new queue item object
      const newQueueItem = {
        imageLink: imageLink
          ? // The profile gave us an image
            imageLink.data.data[0].profile_image_url
          : // Or a placeholder
            "http://www.pngimg.com/uploads/twitch/twitch_PNG56.png",
        // Now give value to the Imagelink based on the response
        userName: user_name,
      };
      roomRef.child("queue").push(newQueueItem);
    });
  };

  const cutText = (text) => {
    if (text.length > 25) {
      return text.slice(0, 25) + "...";
    } else {
      return text;
    }
  };

  const getLogo = (userName, cb) => {
    // Need to handle fail because this api never works
    axios
      .post(
        `${url}/getTwitchProfileImageFromLoginName`,
        {
          channel: userName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        cb(response);
      })
      .catch((err) => {
        cb(false);
      });
  };
  const handleQueueAddPress = (event, user_name) => {
    addToQueue(user_name);
  };

  const renderItem = (index, channel) => {
    const { user_name, title, viewer_count, thumbnail_url } = channel;

    const thumbnailReplaced = thumbnail_url
      .replace("{width}", "440")
      .replace("{height}", "248");
    return (
      <div className="stream-preview">
        <Image
          fluid
          alt={`${user_name} Stream Preview`}
          className="browsingImage"
          src={thumbnailReplaced}
        ></Image>
        <div>
          <div className="controls">
            <Row>
              <div className="buttons">
                <OverlayTrigger
                  key="add-tooltip"
                  placement="right"
                  overlay={<Tooltip id={`add-tooltip`}>Add to queue</Tooltip>}
                >
                  <Button
                    className="control-button"
                    variant="primary"
                    onClick={(event) => {
                      handleQueueAddPress(event, user_name);
                    }}
                  >
                    <i className="fas fa-plus" style={{ color: "white" }}></i>
                  </Button>
                </OverlayTrigger>
                {isYouOwner ? (
                  <OverlayTrigger
                    key="instant-tooltip"
                    placement="right"
                    overlay={
                      <Tooltip id={`instant-tooltip`}>Instant Play</Tooltip>
                    }
                  >
                    <Button
                      className="control-button"
                      variant="primary"
                      onClick={(event) => setFieldForRoom(user_name)}
                    >
                      <i className="fas fa-play" style={{ color: "white" }}></i>
                    </Button>
                  </OverlayTrigger>
                ) : null}
              </div>
            </Row>
            <Row className="titleContainer">
              <Col sm={10}>
                <OverlayTrigger
                  key={`${title}-tooltip`}
                  placement="top"
                  overlay={<Tooltip id={`${title}-tooltip`}>{title}</Tooltip>}
                >
                  <p className="streamTitle">{cutText(title)} </p>
                </OverlayTrigger>
              </Col>
              <Col sm={2}>
                <OverlayTrigger
                  key="count-tooltip"
                  placement="right"
                  overlay={
                    <Tooltip id={`count-tooltip`}>
                      Current viewers on twitch
                    </Tooltip>
                  }
                >
                  <div>
                    <i
                      className="fas fa-user-friends"
                      style={{ display: "inline" }}
                    ></i>
                    <p className="viewerCount">{viewer_count}</p>
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Container fluid style={{ marginLeft: "1vw", marginRight: "1vw" }}>
      <Row>
        {usersPage.map((channel, index) => {
          return (
            <Col key={channel.user_name} className="singleStream" lg={2}>
              {renderItem(index, channel)}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default StreamGrid;
