export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
export function scrollToTop() {
  const rootElement = document.documentElement;
  rootElement.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
