import React, { Component, useEffect } from "react";
import firebase from "../../firestore/init";
import "../../styles/streambrowser.scss";

export default function AuthPage(props) {
  useEffect(() => {
    const { setAppAuth, setAuthError } = props;
    firebase
      .auth()
      .signInAnonymously()
      .then((user) => {
        setAppAuth(user);
      })
      .catch(function (error) {
        setAuthError();
      });
  }, []);

  return (
    <div className="AuthScreen">
      <div className="loader"></div>
    </div>
  );
}
