import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// VERY BAD HARD REDIRECT FUCK YOU GOOGLE
const origin = window.location.origin;
const isDev = process.env.NODE_ENV === "development";

if (origin !== "https://partydredge.com" && !isDev) {
  window.location.replace("https://partydredge.com");
}

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
