import React, { Component, useState } from "react";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import firebase from "../firestore/init";
import "../styles/homepage.scss";
import monkas from "./../img/Monkas.png";
import cryptoRandomString from "crypto-random-string";

const database = firebase.database();

function NewRoom(props) {
  const [redirectRoom, setRedirect] = useState(false);

  const createRoom = () => {
    const { userId } = props;
    const id = cryptoRandomString({
      length: 10,
      type: "url-safe",
    });

    database.ref("/Room/" + id).set(
      {
        currentStream: "moonmoon",
        ownerId: userId,
      },
      () => {
        setRedirect(id);
      }
    );
  };

  return (
    <div style={{ width: "100%" }}>
      {redirectRoom ? (
        <Redirect to={`/room/${redirectRoom}`} />
      ) : (
        <Button
          className="create-room-button"
          primary
          onClick={() => createRoom()}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={monkas} wdith={50} height={50} />
            <p style={{ margin: 0, padding: 0 }}>Explore the dredges</p>
            <img src={monkas} wdith={50} height={50} />
          </div>
        </Button>
      )}
    </div>
  );
}

export default NewRoom;
