import React, { createContext, useReducer, useContext } from "react";
import { useParams } from "react-router-dom";
import presence from "../firestore/presence";

/* Action Types */
const SET_CURRENT_STREAM = "SET_CURRENT_STREAM";
const SET_ENFORCED_STREAM = "SET_ENFORCED_STREAM";

/* Define a context and a reducer for updating the context */
const QueueState = createContext();

const initialState = {
  currentStream: false,
  enforcedStream: false,
  keysArray: [],
};

const queueStateReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_STREAM:
      return {
        ...state,
        currentStream: action.payload,
      };
    case SET_ENFORCED_STREAM:
      return {
        ...state,
        enforcedStream: action.payload,
      };
    default:
      return state;
  }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const QueueStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(queueStateReducer, initialState);

  return (
    <QueueState.Provider value={[state, dispatch]}>
      {children}
    </QueueState.Provider>
  );
};

/* 
Default export is a hook that provides a simple API for updating the global state. 
This also allows us to keep all of this state logic in this one file
*/

const useQueueState = () => {
  const [state, dispatch] = useContext(QueueState);

  const setEnforcedStream = (userName) => {
    dispatch({
      type: SET_ENFORCED_STREAM,
      payload: userName,
    });
  };

  const setCurrentStream = (userName) => {
    dispatch({
      type: SET_CURRENT_STREAM,
      payload: userName,
    });
  };

  return {
    setEnforcedStream,
    setCurrentStream,
    currentStream: state.currentStream,
    enforcedStream: state.enforcedStream,
  };
};

export default useQueueState;
