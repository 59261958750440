import React, { createContext, useReducer, useContext } from "react";
import { useParams } from "react-router-dom";
import presence from "../firestore/presence";

/* Action Types */
const SET_OWNER_ID = "SET_OWNER_ID";
const SET_IS_YOU_OWNER = "SET_IS_YOU_OWNER";

/* Define a context and a reducer for updating the context */
const RoomState = createContext();

const initialState = {
  ownerId: "",
  isYouOwner: false,
};

const roomStateReducer = (state, action) => {
  switch (action.type) {
    case SET_OWNER_ID:
      return {
        ...state,
        ownerId: action.payload,
      };
    case SET_IS_YOU_OWNER:
      return {
        ...state,
        isYouOwner: action.payload,
      };
    default:
      return state;
  }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const RoomStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(roomStateReducer, initialState);

  return (
    <RoomState.Provider value={[state, dispatch]}>
      {children}
    </RoomState.Provider>
  );
};

/* 
Default export is a hook that provides a simple API for updating the global state. 
This also allows us to keep all of this state logic in this one file
*/

const useRoomState = () => {
  const [state, dispatch] = useContext(RoomState);

  const setIsYouOwner = (userId, ownerId) => {
    dispatch({
      type: SET_IS_YOU_OWNER,
      payload: ownerId === userId,
    });
  };

  const setOwnerId = (ownerId) => {
    dispatch({
      type: SET_OWNER_ID,
      payload: ownerId,
    });
  };

  return {
    setOwnerId,
    setIsYouOwner,
    isYouOwner: state.isYouOwner,
    ownerId: state.ownerId,
  };
};

export default useRoomState;
