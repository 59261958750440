import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RoomHandler from "../../firestore/rooms";
import "./../../styles/mainstreamview.scss";
// Container Elements
import StreamBrowser from "../StreamBrowser";
import MainStreamView from "../MainStreamView";
import Queue from "../Queue";
import AdminPanel from "../AdminPanel";
import VisibleUsers from "../VisibleUsers";
// Components
import TwitchChat from "../../components/roompage/TwitchChat";

// State
import useQueueState from "../../hooks/queue-state";
// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import roomState from "./../../hooks/room-state";
import { CopyUrl } from "../../components/CopyUrl";

function RoomPage(props) {
  //our room ref for firebase
  const [roomRef, setRoomRef] = useState(null);
  // Grants us access to the state manager for the visible stream
  const queueState = useQueueState();
  // Queue State
  const { setCurrentStream, setEnforcedStream, enforcedStream, currentStream } =
    queueState;
  const { isYouOwner } = roomState;
  const { id } = useParams();
  const { userId } = props;

  useEffect(() => {
    setRoomRef(RoomHandler.getRoomRefFromId(id));
  }, [id]);
  // Front queue sub that will propogate
  useEffect(() => {
    if (roomRef) {
      // This gets the first item from the queue list. Always in order per firebase
      roomRef
        .child("queue")
        .limitToFirst(1)
        .on("value", (snapshot) => {
          const val = snapshot.val();
          if (val) {
            const uid = Object.keys(val)[0];
            setCurrentStream(val[uid].userName);
          } else {
            setCurrentStream(null);
          }
        });
    }
  }, [roomRef]);
  // enforcedStream sub that will
  useEffect(() => {
    if (roomRef) {
      roomRef.child("enforcedStream").on("value", (snapshot) => {
        const val = snapshot.val();
        if (val) {
          console.log(val);
          const userNameEnforced = val;
          setEnforcedStream(userNameEnforced);
        } else {
          setEnforcedStream(null);
        }
      });
    }
    return () => {
      if (roomRef && isYouOwner) {
        console.log("HERE");
        // THIS ISNT WORKING BUT IT MUST BE CLOSE?!?! LEAVING
        console.log("deleting");
        console.log(roomRef.child(id));
        roomRef.remove();
      }
    };
  }, [roomRef]);

  return (
    <Container fluid>
      <div className="top-info-container">
        <div className="button-group-top-info">
          <AdminPanel roomRef={roomRef} roomId={id} userId={userId} />
          <VisibleUsers roomID={id} currentUser={userId} />
        </div>
        <CopyUrl />
      </div>
      <div className="stream-view-container">
        <section className="queue-container">
          <Queue
            roomRef={roomRef}
            enforcedStream={enforcedStream}
            currentStream={currentStream}
          />
        </section>
        <section className="video-player-container">
          <MainStreamView
            enforcedStream={enforcedStream}
            currentStream={currentStream}
          />
        </section>
        <section className="twchat-container">
          <TwitchChat
            enforcedStream={enforcedStream}
            currentStream={currentStream}
          />
        </section>
      </div>
      <Row lg={12}>
        <StreamBrowser roomRef={RoomHandler.getRoomRefFromId(id)} />
      </Row>
    </Container>
  );
}

export default RoomPage;
