import React, { useState, useEffect } from "react";
import Twitch from "react-twitch-embed-video";
import "../styles/mainstreamview.scss";
import "../styles/streambrowser.scss";

import RoomHandler from "../firestore/rooms";
import useQueueState from "../hooks/queue-state";
import { TwitchPlayer } from "react-twitch-embed";

function MainStreamView(props) {
  const [playingStream, setPlayingStream] = useState(props.currentStream);
  const [loading, setLoading] = useState(false);
  // Queue state for current
  const queueState = useQueueState();
  const { currentStream, enforcedStream } = queueState;
  // The Video Player Loader
  useEffect(() => {
    setPlayingStream(enforcedStream || currentStream || null);
  }, [props]);

  return (
    <div className="">
      {playingStream ? (
        <TwitchPlayer
          channel={playingStream}
          id="main-iframe-mount"
          theme="dark"
          muted
          height="720px"
          width="100%"
          // parent={["localhost", "party-dredge.wep.app"]}
        />
      ) : (
        <p style={{ color: "rgba(255,255,255,0.7)" }}>
          Add (+) a stream to queue!
        </p>
      )}
    </div>
  );
}

export default MainStreamView;
