import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/party-dredge/us-central1"
    : "https://us-central1-party-dredge.cloudfunctions.net";
export default {
  getAllStreams: (category) => {
    return axios
      .get(`${url}/getMostRecentStream?category=${category}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  setStreams: () => {
    return axios
      .post(`${url}/setStreams`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getTopCategories: () => {
    return axios
      .post(`${url}/getTopStreams`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
