import React from "react";
import { useClipboard } from "use-clipboard-copy";
// Bootstrap
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

export const CopyUrl = () => {
  const copyUrl = window.location.href;
  const clipboard = useClipboard({
    copiedTimeout: 600,
  });

  return (
    <div>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text
            style={{
              background: "#121212",
              color: "rgba(255,255,255,0.7)",
            }}
            id="copy-id"
          >
            Room
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          style={{ background: "#121212", color: "rgba(255,255,255,0.7)" }}
          disabled
          ref={clipboard.target}
          value={`${copyUrl}`}
        />
        <InputGroup.Append>
          <Button
            onClick={clipboard.copy}
            style={{
              border: "1px solid white",
              background: "#121212",
              color: "#9146ff",
            }}
          >
            {clipboard.copied ? <i className="fa fa-check"></i> : "Copy"}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};
