import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Custom css
import "./App.scss";
// Pages
import RoomPage from "./containers/pages/RoomPage";
import HomePage from "./containers/pages/HomePage";
import AuthPage from "./containers/pages/AuthPage";
// State Providers
import { RoomStateProvider } from "./hooks/room-state";
import { QueueStateProvider } from "./hooks/queue-state";

function App() {
  const [authUser, setAuthUser] = useState(false);
  const [authError, setAuthError] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      setAuthUser(authUser);
    }
  }, []);

  const setUserStateFromChild = (authUser) => {
    if (!localStorage.getItem("authUser")) {
      localStorage.setItem("authUser", JSON.stringify(authUser));
      setAuthUser(authUser);
    }
  };
  const setAuthErrorStateFromChild = (error) => {
    setAuthError(error);
  };

  return (
    <div className="App">
      {authError ? <p>Auth error</p> : null}
      {!authUser ? (
        <AuthPage
          setAuthError={(error) => setAuthErrorStateFromChild(error)}
          setAppAuth={(user) => setUserStateFromChild(user)}
        />
      ) : (
        <Router>
          {/* <NavBar /> */}
          <Switch>
            <Route
              path="/room/:id"
              render={(routeProps) => (
                <RoomStateProvider>
                  <QueueStateProvider>
                    <RoomPage
                      routeProps={routeProps}
                      userId={authUser.user.uid}
                    />
                  </QueueStateProvider>
                </RoomStateProvider>
              )}
            />
            <Route path="/">
              <HomePage userId={authUser.user.uid} />
            </Route>
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
