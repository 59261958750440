import React, { useState, useEffect } from "react";
// Components
import StreamGrid from "../components/streambrowser/StreamGrid";
import Pagination from "react-js-pagination";
// Styles
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import "../styles/streambrowser.scss";

// Utils
import TwitchService from "./../utils/twitch";
import { scrollToTop } from "../helpers/general";
import { CategoryPicker } from "./CategoryPicker";

// Const
const streamsPerPage = 18;
const defaultCategory = {
  value: "509658",
  label: "Just Chatting",
};

function StreamBrowser(props) {
  const roomRef = props.roomRef;
  const [streamsList, setStreamsList] = useState([]);
  const [notifcations, setNotifications] = useState();
  const [loading, setLoadingStreamsState] = useState(false);
  const [omegaLoad, setOmegaLoad] = useState(false);
  const [activePage, setCurrentPage] = useState(1);
  const [currentCategory, setCurrentCategory] = useState(defaultCategory);
  // Pagination logic
  const indexOfLastStream = activePage * streamsPerPage;
  const indexOfFirstStream = indexOfLastStream - streamsPerPage;
  const currentStreams = streamsList.slice(
    indexOfFirstStream,
    indexOfLastStream
  );

  // Life cycle hooks, if cleanups needed include them in a return statement
  // Array is passed as second argument to indicate only load on mount
  useEffect(() => {
    // First check LocalStorage for channels and eval last updated
    // We will force a refresh every hour, which should cover a gap of view sessions,
    // otherwise leaving it up to the user
    const store = window.localStorage.getItem("store");
    // No store so we need a refresh
    if (!store) {
      refreshLocalStorageWithStreams();
    } else {
      if (currentCategory != store["currentCategory"]) {
        refreshLocalStorageWithStreams();
      }
      const localStorageStore = JSON.parse(store);
      const now = Date.now();
      const last_time_saved_storage = localStorageStore.updated_at;
      if (now - last_time_saved_storage > 60 * 600000) {
        // Every hour refresh
        refreshLocalStorageWithStreams();
      } else {
        setStreamsList(localStorageStore.streamsList);
      }
    }
  }, [currentCategory]);
  useEffect(() => {}, [notifcations]);
  // Array is passed as second argument to indicate only load on mount
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Set viewed stream
  const setFieldForRoom = (newUser) => {
    roomRef.update({ enforcedStream: newUser });
  };
  // Calls twitch api and sets our localstreams array
  const refreshLocalStorageWithStreams = async () => {
    let omegaLoadFlag = setTimeout(() => {
      setOmegaLoad(true);
    }, 6000);
    setLoadingStreamsState(true);
    const streams = await TwitchService.getAllStreams(currentCategory.value);
    if (streams) {
      const streamsList = streams.reverse();
      const store = {
        streamsList,
        updated_at: Date.now(),
        currentCategory,
      };
      const encodeStore = JSON.stringify(store);
      window.localStorage.setItem("store", encodeStore);
      clearTimeout(omegaLoadFlag);
      setOmegaLoad(false);
      setLoadingStreamsState(false);
      setStreamsList(streamsList);
    }
  };
  return (
    <>
      {/* <Notifications /> */}
      <div className="browserButtons">
        <CategoryPicker
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
        />
        <div className="browserButton">
          <Button
            disabled={loading}
            className="see-more"
            size="lg"
            variant="primary"
            onClick={() => refreshLocalStorageWithStreams()}
          >
            {!loading ? (
              "See new streams"
            ) : (
              <i className="fa-spin fas fa-tv"></i>
            )}
          </Button>
        </div>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={`buttonHelp`}>
              <strong>Back to stream</strong>.
            </Tooltip>
          }
        >
          <div className="browserButton">
            <Button
              className="browserButton toTop"
              size="lg"
              variant="primary"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </Button>
          </div>
        </OverlayTrigger>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={18}
          totalItemsCount={streamsList.length}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
        />
      </div>
      <Collapse in={omegaLoad}>
        <h5 style={{ marginLeft: "1rem" }}>
          This is taking a long time, you're probably loading all of the
          streams, this happens every once and awhile to ensure fresh content.
        </h5>
      </Collapse>
      <Row className="Browser">
        <StreamGrid
          usersPage={currentStreams}
          setFieldForRoom={(user_name) => setFieldForRoom(user_name)}
        />
      </Row>
      <div
        className="pagination-container"
        style={{ marginTop: 50, marginBottom: 30 }}
      >
        {!loading ? (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={18}
            totalItemsCount={streamsList.length}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
        ) : null}
      </div>
    </>
  );
}

StreamBrowser.propTypes = {};

export default StreamBrowser;
