import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import NewRoom from "../NewRoom";
import "./../../styles/homepage.scss";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container>
        <Jumbotron
          style={{ background: "#121212", color: "rgba(255, 255, 255, 0.86)" }}
        >
          <h1>Welcome to Party Dredge</h1>
          <h3>Check out the dredges of just chatting</h3>
          <hr></hr>
          <h4>How to best enjoy:</h4>
          <ol className="how-to-use">
            <li>
              <b>Get in a discord call with friends</b>
            </li>
            <li>
              <b>
                Send your friends the url
                (https://partydredge.com/whateveryourroomis)
              </b>
            </li>
            <li>
              <b>Load new streams</b>
            </li>
            <li>
              <b>Start adding streams to the queue and enjoy</b>
            </li>
          </ol>
          <p>
            <NewRoom userId={this.props.userId} />
          </p>
        </Jumbotron>
      </Container>
    );
  }
}

export default HomePage;
