import React, { useState, useEffect } from "react";
import "../../styles/streambrowser.scss";
import RoomHandler from "../../firestore/rooms";

function TwitchChat(props) {
  // Set our state
  const [currentStream, setCurrentStream] = useState(props.currentStream);
  const [loading, setLoading] = useState(false);
  const parent =
    process.env.NODE_ENV === "development" ? "localhost" : "partydredge.com";
  // When props change
  useEffect(() => {
    setCurrentStream(props.currentStream);
  }, [props.currentStream]);

  return (
    <div>
      {currentStream ? (
        <iframe
          src={`https://www.twitch.tv/embed/${currentStream}/chat?darkpopout&parent=${parent}`}
          height="714"
          width="100%"
          style={{ border: "none" }}
        ></iframe>
      ) : (
        ""
      )}
    </div>
  );
}

export default TwitchChat;
