import React, { useState, useEffect } from "react";
import "../styles/visibleUsers.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import presenceHandler from "../firestore/presence";
import firebase from "../firestore/init";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import useRoomState from "../hooks/room-state";

function VisibleUsers(props) {
  const [onlineUsers, setOnlineUsers] = useState(false);
  const [fetchingUsername, setFetchingUsername] = useState(false);
  const [usernameField, setField] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const [fieldSuccess, setFieldSuccess] = useState(false);
  const [usernameMap, setUsernameMap] = useState({});
  const [usersArray, setUsersArray] = useState([]);
  const [show, setShow] = useState(false);
  const roomState = useRoomState();
  const { isYouOwner } = roomState;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const { currentUser, roomID } = props;
    presenceHandler.InsertPresenceWithID(roomID, currentUser, isYouOwner);

    firebase
      .database()
      .ref("/Room/" + roomID + "/status")
      .on("value", (onlineUsers) => {
        if (onlineUsers) {
          const users = onlineUsers.val();
          setOnlineUsers(users);
        }
      });
    return function cleanup() {
      firebase
        .database()
        .ref("/Room/" + roomID + "/status")
        .off("value");
    };
  }, []);

  useEffect(() => {
    setUsersArray(filterOfflineUsers(onlineUsers));
  }, [onlineUsers]);

  useEffect(() => {
    fetchEachUserName(usersArray);
  }, [usersArray]);

  const failInput = () => {
    setFieldError(true);
  };
  const passInput = () => {
    setFieldSuccess(true);
    setField("");
  };
  const normalInpt = () => {
    setFieldSuccess(false);
    setFieldError(false);
    setField("");
  };

  const filterOfflineUsers = (statusObject) => {
    let onlineUsers = [];
    for (const id in statusObject) {
      const user = statusObject[id];
      const { state } = user;
      if (state === "online") {
        onlineUsers.push(id);
      }
    }
    return onlineUsers;
  };

  const fetchEachUserName = (users) => {
    for (const id in users) {
      const user = users[id];
      fetchAndReturnUsername(user, (username) => {
        setUsernameMap({ ...usernameMap, [user]: username });
      });
    }
  };

  const fetchAndReturnUsername = (id, callback) => {
    firebase
      .database()
      .ref(`/sessionInfo/${id}`)
      .on("value", (username) => {
        username.val() ? callback(username.val().username) : callback(false);
      });
  };

  const handleUserNameSave = (e) => {
    e.preventDefault();
    setFetchingUsername(true);
    const { currentUser } = props;
    // Validation
    if (
      usernameField !== "" &&
      usernameField.replace(/\s/g, "") !== "" &&
      usernameField.length < 15 &&
      usernameField !== usernameMap[currentUser]
    ) {
      // Success
      presenceHandler.changeUsername(
        currentUser,
        usernameField,
        (wroteSucess) => {
          setFetchingUsername(false);
        }
      );
      setTimeout(() => {
        normalInpt();
      }, 1000);
      passInput();
    } else {
      // Failure
      setTimeout(() => {
        setFetchingUsername(false);
        normalInpt();
      }, 1000);
      failInput();
    }
  };

  const handleUsernameChange = (event) => {
    event.preventDefault();
    setField(event.target.value);
  };

  const InfoButton = () => (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id={`infobutton`}>
          <strong>Current users:</strong>
          {usersArray.map((user, index) => {
            return (
              <p key={usernameMap[user] + index} style={{ display: "block" }}>
                {usernameMap[user]}
              </p>
            );
          })}
        </Tooltip>
      }
    >
      <Button
        onClick={() => handleShow()}
        className="infoButton"
        variant="primary"
      >
        <i className="fas fa-user-friends" style={{ marginRight: 8 }}></i>
        {usersArray.length}
      </Button>
    </OverlayTrigger>
  );
  console.log(isYouOwner);
  return (
    <>
      <InfoButton />
      <Modal show={show} onHide={handleClose} className="app-modal">
        <Modal.Header closeButton>
          <Modal.Title>Room Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                isInvalid={fieldError}
                isValid={fieldSuccess}
                value={usernameField}
                onChange={(e) => handleUsernameChange(e)}
                type="text"
                placeholder="Meme name goes here"
              />
              <Form.Text className="text-muted">
                Enter a one time username
              </Form.Text>
            </Form.Group>
          </Form>
          {isYouOwner ? (
            <>
              <p>Manage users</p>
              {usersArray.map((user, index) => {
                return (
                  <div style={{ display: "flex" }}>
                    <p
                      key={usernameMap[user] + index}
                      style={{ display: "block" }}
                    >
                      {usernameMap[user]}
                    </p>
                    <i class="fas fa-crown"></i>
                  </div>
                );
              })}
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            onClick={(e) => handleUserNameSave(e)}
            variant="primary"
            type="button"
          >
            {!fetchingUsername ? (
              "Submit"
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VisibleUsers;
